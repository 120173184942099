import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-white text-primary-900`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-black mb-4`;
const Heading = tw(SectionHeading)`text-black`;
const Description = tw(SectionDescription)`mx-auto text-center text-black`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-900 hover:border-primary-900 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-black`;

export default function FAQ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Please contact us at maskillinois@gmail.com if you have any further questions.",
  faqs = [
    {
      question: "What is the goal behind this project ?",
      answer:
        "Our goal as a student led organization is to help alleviate as many pain points during the pandemic as possible. People have found it hard to obtain masks, so through this app we hope to connect mask makers and mask needers to get masks to those who really need them."
    },
    {
      question: "Why should I make masks for free ?",
      answer:
        "By signing up to be a donor, you are doing an incredible service that helps countless people and could even save lives. Additionally, you will be making masks directly for those in your community, so you will be able to see the direct impacts of your hard work."
    },
    {
      question: "Are masks really that effective ?",
      answer:
        "Absolutely! Although rhetoric was unclear and even contradictory at the beginning of the pandemic, multiple studies have now been done that prove and model how cases would sharply drop off if a great enough percentage of the population wears masks. Your mask prevents you from spreading the virus and other peoples’ masks prevent them from spreading the virus to you if they might have it."
    },
    {
      question: "Do I have to follow the videos exactly ? What if I have my own method for making masks ?",
      answer:
        "The videos we added are a great reference to get started with making masks, but they do not have to be followed exactly. However, you must follow the quality guidelines posted so there is a level of consistency. Feel free though to make your own designs and patterns, don’t be afraid to get creative!"
    }/* ,
    {
      question: "If we are looking for donors and find somebody we want, how do we recieve our mask ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      question: "Where are the servers located ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    } */
  ]
}) {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>Please contact us at <a href="mailto:name@gmail.com"><u>maskillinois@gmail.com</u></a> if you have any further questions.</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
